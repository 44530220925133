{
  "name": "chatpad",
  "private": true,
  "source": "src/index.html",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start": "parcel",
    "build": "parcel build"
  },
  "devDependencies": {
    "@types/downloadjs": "^1.4.3",
    "@types/react": "^18.0.28",
    "@types/react-dom": "^18.0.11",
    "axios": "^1.3.5",
    "buffer": "^5.7.1",
    "form-data": "^4.0.0",
    "parcel": "^2.8.3",
    "process": "^0.11.10"
  },
  "dependencies": {
    "@emotion/react": "^11.10.6",
    "@emotion/server": "^11.10.0",
    "@mantine/core": "^6.0.1",
    "@mantine/hooks": "^6.0.1",
    "@mantine/next": "^6.0.1",
    "@mantine/notifications": "^6.0.1",
    "@tabler/icons-react": "^2.9.0",
    "@tanstack/react-location": "^3.7.4",
    "@types/node": "18.15.0",
    "@types/react": "18.0.28",
    "@types/react-dom": "18.0.11",
    "dexie": "^3.2.3",
    "dexie-export-import": "^4.0.6",
    "dexie-react-hooks": "^1.1.3",
    "downloadjs": "^1.4.7",
    "eslint": "8.36.0",
    "eslint-config-next": "13.2.4",
    "lodash": "^4.17.21",
    "nanoid": "^4.0.1",
    "next": "13.2.4",
    "openai": "^3.2.1",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-icons": "^4.8.0",
    "typescript": "4.9.5"
  }
}
